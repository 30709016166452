import * as React from "react"
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CategoryLayout from "../components/layout/CategoryLayout";
import PostCardGroup from "../components/templates/PostCardGroup";
import { FaHome,FaFolder,FaRegCalendarAlt } from "react-icons/fa";
import {Helmet} from "react-helmet";
import {TagListQuery} from "../../graphql-types";

type Props = {
  data : TagListQuery
  pageContext: any
}

const TagPage = (props: Props) => {
  const { title:siteTitle,description:siteDesc,siteUrl } = props.data.site.siteMetadata;
  const tag = props.pageContext.tag;
  const Wrapper = styled.div`
    .pankuzu{
      font-size:13px;
    }
    .pankuzu a{
      text-decoration: none;
      color:#333;
    }
    h1{
      text-align: center;
      margin: 25px 0;
      font-weight: bold;
      font-size: 28px;
    }
  `;
  return (
    <CategoryLayout data={props.data}>
      <Helmet>
        <html lang="ja" />
        <title>タグ:{tag} | {siteTitle}（{siteDesc}）</title>
        <meta name="description" content={siteDesc} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`カテゴリ:${tag} | ${siteTitle}（${siteDesc}）`} />
        <meta property="og:description" content={siteDesc} />
        <meta property="og:image" content={`${siteUrl}/logo.png`} />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:width" content="1200" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`${siteUrl}/logo.png`} />
        <meta name="twitter:title" content={`カテゴリ:${tag} | ${siteTitle}（${siteDesc}）`} />
        <meta name="twitter:description" content={siteDesc} />
      </Helmet>
      <Wrapper>
        <p className='pankuzu'>
          <Link to="/"><FaHome /> HOME</Link> &gt; 
          <Link to={`/tag/`+tag}> <FaFolder /> タグ:{tag} </Link>
        </p>
        <h1>タグ: {tag}</h1>
        <PostCardGroup data={props.data.allMdx} />
      </Wrapper>
    </CategoryLayout>
  )
}

export const query = graphql`
  query TagList($tag: String) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        headerLinks {
          label
          url
        }
      }
    }
    allMdx(
        limit: 2000
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
          frontmatter: { 
            tags: { in: [$tag] } 
            publish: { eq: true }
          } 
        }
    ) {
      nodes {
        id
        frontmatter {
          slug
          date
          title
          category
          posttype
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`;


export default TagPage
